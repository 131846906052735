import React from 'react';
import './pages.css';

const HomePage = () => {
  return (
    <div className="home-page">
      <h1>Hello World!</h1>
    </div>
  );
};

export default HomePage;
